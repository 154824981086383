@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Inter';
  font-weight: 300; /* Light */
  src: url('./assets/fonts/Inter-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400; /* Regular */
  src: url('./assets/fonts/Inter-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500; /* Medium */
  src: url('./assets/fonts/Inter-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600; /* Semibold */
  src: url('./assets/fonts/Inter-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700; /* Bold */
  src: url('./assets/fonts/Inter-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Plus Jakarta';
  font-weight: 300;
  src: url('./assets//fonts/PlusJakartaDisplay-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Plus Jakarta';
  font-weight: 400;
  src: url('./assets//fonts/PlusJakartaDisplay-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Plus Jakarta';
  font-weight: 500;
  src: url('./assets//fonts/PlusJakartaDisplay-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Plus Jakarta';
  font-weight: 700;
  src: url('./assets//fonts/PlusJakartaDisplay-Bold.otf') format('opentype');
}


body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: #11BBF8 !important;
}

.rhap_button-clear.rhap_volume-button {
  color: #11BBF8 !important;
}

.rhap_volume-bar, .rhap_volume-indicator {
  background-color: #11BBF8 !important;
}
.rhap_progress-section .rhap_time {
  font-size: 12px !important;
  color: #0A0F1F !important;
}